import { pipe, map, fromPairs, values, omit } from 'ramda'
import { removeAppShellPrPrefix } from 'utils/urlTransition'
import { EpiBlock, EpiBlockProperties } from '@trr/internaltypes'

import configService from '../configService'
import { UserProfileImages } from 'state/PublicWebApi/UserProfile'

export const removeTrailingSlash = (url: string): string =>
  url.replace(/\/$/, '')

export const getPageName = (...names: string[]): string =>
  names.length ? `TRR: ${names.join(' - ')}` : 'TRR'

export const dictionaryToObject = pipe<
  Record<string, unknown>[],
  unknown[][],
  Record<string, unknown>
>(map(values), fromPairs)

/**
 * Converts and flattens Epi block to Record, removing child dictionaries
 * @param {EpiBlock} block - block to convert
 * @returns {Record} flattened object
 */
export const filterContentBlockAreaToObject = (
  block: EpiBlock
): Record<string, unknown> => {
  const keys = Object.values(block.properties.contentBlockArea).map(
    ({ properties }: { properties: EpiBlockProperties }) => {
      const customDictionaryObject = properties.customDictionary && {
        ...dictionaryToObject(properties.customDictionary),
      }
      const customLinkDictionaryObject = properties.customLinkDictionary && {
        ...dictionaryToObject(properties.customLinkDictionary),
      }

      const blockWithOmittedProperties = omit(
        ['customDictionary', 'blockKey', 'customLinkDictionary'],
        properties
      )

      return {
        [properties.blockKey]: {
          ...customDictionaryObject,
          ...customLinkDictionaryObject,
          ...blockWithOmittedProperties,
        },
      }
    }
  )
  return keys.reduce((contentBlock, currentBlock) => {
    return { ...contentBlock, ...currentBlock }
  }, {})
}

export const getProfileImages = (fileName: string): UserProfileImages => {
  const imageBaseUrl = `${configService.config.MEDIA_URL}/user-profile-images/`
  enum UserProfileImageWidths {
    tiny = 32,
    small = 48,
    medium = 80,
    large = 112,
    xlarge = 160,
    xxlarge = 320,
  }

  const defaultProfileImages = {
    tiny: '',
    small: '',
    medium: '',
    large: '',
    xlarge: '',
    xxlarge: '',
  }

  const sizes: UserProfileImages = fileName
    ? Object.keys(defaultProfileImages).reduce(
        (
          result: UserProfileImages,
          size: keyof UserProfileImages
        ): UserProfileImages => {
          result[size] =
            `${imageBaseUrl}${fileName}&width=${UserProfileImageWidths[size]}`
          return result
        },
        defaultProfileImages
      )
    : defaultProfileImages

  return sizes
}

export const pageIsSummaryInEnglish = (path: string): boolean => {
  const pathWithoutPullRequestPrefix = path && removeAppShellPrPrefix(path)
  return pathWithoutPullRequestPrefix?.slice(0, 19) === '/summary-in-english'
}

export const asyncCopyToClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text)
}

export const asyncMobilePageShare = async (url: string) => {
  try {
    await navigator.share({ url: url })
  } catch (error) {
    console.error(error)
  }
}

export const replaceVariablesInText = (
  text: string,
  variables: { [key: string]: string }
): string => {
  const regex = (key: string): RegExp => new RegExp(`{{${key}}}`, 'g')
  Object.keys(variables).forEach(
    (key) => (text = text.replace(regex(key), variables[key]))
  )
  return text
}
