import { __api_internals__ } from '@trr/app-shell-communication'
import { getLoginUrl } from 'features/Authentication'
import userManagerAdapter from 'features/Authentication/userManager'
import {
  UserType,
  forceLogout,
  removeRepeatedSlashesFromUrl,
} from 'features/Authentication/helpers'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useEventListener } from 'utils/hooks'
import { publicWebApi } from 'state/PublicWebApi'

const eventNames = __api_internals__.eventNames

const useAuthenticationEventListeners = () => {
  const history = useHistory()

  const dispatch = useDispatch()

  useEventListener(eventNames.logout, () => {
    forceLogout()
  })

  // redirect to login MF
  useEventListener<
    CustomEvent<{
      redirectUrl: string
      userType: UserType
      historyAction: 'push' | 'replace'
    }>
  >(eventNames.login, (evt) => {
    const originalRedirectUrl = evt.detail.redirectUrl
    const redirectUrl = removeRepeatedSlashesFromUrl(originalRedirectUrl)

    const state = {
      redirectUrl,
    }
    const loginPath = getLoginUrl(
      history.location.pathname,
      evt.detail.userType
    )

    // we have to use ?? to be backwards compatible with the library
    history[evt.detail.historyAction ?? 'push'](loginPath, state)
  })

  useEventListener(eventNames.refreshUserProfile, () => {
    const signInSilentAndRefreshUser = async () => {
      await userManagerAdapter.signinSilent({
        state: { refreshUserProfile: true },
      })
      dispatch(publicWebApi.util.invalidateTags(['UserProfile']))
    }
    void signInSilentAndRefreshUser()
  })
}

export default useAuthenticationEventListeners
