import { useEffect } from 'react'
import { buildCustomEvent } from 'features/Telemetry/GoogleTagManager/Helpers/buildCustomEvent'
import { buildSyntheticPageViewFromCustomEvent } from 'features/Telemetry/GoogleTagManager/Helpers/buildSyntheticPageViewFromCustomEvent'
import { gtmCustomEvent, __api_internals__ } from '@trr/app-shell-communication'
import TagManager from 'features/Telemetry/GoogleTagManager/TagManager'
import { useCachedFeatureFlagsState } from 'PublicWebApi'
import { useSliceStateSelector } from 'slices/hooks'
import { gtmLogger } from 'features/Telemetry/GoogleTagManager/gtmLogger'

const eventNames = __api_internals__.eventNames

export const useTrackCustomEventListener = () => {
  const isTrrCookiesApproved = useSliceStateSelector(
    (state) => state.slices.cookies.isTrrCookiesApproved
  )
  const flags = useCachedFeatureFlagsState()
  useEffect(() => {
    const eventCallback = (e: gtmCustomEvent) => {
      const isMfAllowingTracking =
        flags.includes(`gtm.enabled.${e.detail.mfName}`) ?? false
      const isGtmEnabledBlockOverridden =
        e.detail.overrideGtmEnabledBlock ?? false
      if (
        gtmLogger.errorCustom(
          isMfAllowingTracking || isGtmEnabledBlockOverridden,
          'isElementWithinTrackableArea',
          e.detail
        )
      ) {
        TagManager.dataLayer(buildCustomEvent(e.detail))

        /*
          Create a view_page-event for all custom events with sub_view_name.
          This is a requirement from Analytics at TRR and should be evalutated.
        */
        const syntheticPageViewEvent = buildSyntheticPageViewFromCustomEvent(
          e.detail
        )

        if (syntheticPageViewEvent) {
          TagManager.dataLayer(syntheticPageViewEvent)
        }
      }
    }

    if (isTrrCookiesApproved) {
      addEventListener(eventNames.gtmCustomEventName, eventCallback)
    }

    return () => {
      removeEventListener(eventNames.gtmCustomEventName, eventCallback)
    }
  }, [flags, isTrrCookiesApproved])
}
