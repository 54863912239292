import React, { Suspense, useMemo } from 'react'
import { ExternalAssetsBoundary } from 'features/ErrorHandling/ExternalAssetsBoundary'
import { AppShellDataProps } from '@trr/app-shell-data'
import { WidgetLoaderProps } from 'features/SystemLoaders/WidgetLoader/WidgetLoader.types'
import { useWidgetSettings } from './hooks'
import { useSharedState } from 'features/SystemLoaders/MicroFrontendLoader/hooks/useSharedState'
import LazyLoading from 'features/SystemLoaders/LazyLoading'
import { LazyLoadWrapper } from '@trr/internal-helpers'
import { useCachedFeatureFlagsState } from 'state/PublicWebApi'
import { Box, CircularProgress } from '@mui/material'

const WidgetLoader = ({ widgetType }: WidgetLoaderProps) => {
  const { content } = useWidgetSettings(widgetType)
  const widgetContent = content ?? {}
  const appShellData = useSharedState(widgetType, {})
  const widgetData: AppShellDataProps = {
    ...appShellData,
    content: widgetContent,
  }
  const flags = useCachedFeatureFlagsState()

  const LazyWidgetLoader = useMemo(
    () =>
      React.lazy(() => {
        return System.import(widgetType)
      }),
    [widgetType]
  )

  const hasMfTrackableFlag =
    flags.includes(`gtm.enabled.${widgetType}`) ?? false

  const isDisabled = flags.includes(`dashboard.${widgetType}.disabled`)

  if (isDisabled) {
    return null
  }

  return (
    <Box>
      <LazyLoadWrapper>
        <ExternalAssetsBoundary assetType="widget">
          <Suspense fallback={<LazyLoading component={<CircularProgress />} />}>
            <div
              data-mf-loaded={widgetType}
              data-mftrackable={hasMfTrackableFlag}
            >
              <LazyWidgetLoader appShellData={widgetData} />
            </div>
          </Suspense>
        </ExternalAssetsBoundary>
      </LazyLoadWrapper>
    </Box>
  )
}

export default WidgetLoader
