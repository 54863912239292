import VideoPlayButton from 'apps/Site/components/VideoPlayer/VideoPlayButton/VideoPlayButton'
import React, { useCallback, useEffect, useState } from 'react'
import Player from '@vimeo/player'
import { Box, CircularProgress, IconButton, styled } from '@mui/material'
import { FontIcon } from '@trr/internal-helpers'

import FallbackImage from '../FallbackImage'

import {
  loadVideo,
  playVideo,
  pauseVideo,
  getRandomVideoElementId,
  getDuration,
  getVideoId,
  onPlayCallback,
  onLoadedCallback,
  onPlayingCallback,
  onPauseCallback,
  getVideoTitle,
} from './VimeoControls'

const StyledIconButton = styled(IconButton)(() => ({
  left: 'calc(100% - 50px)',
  position: 'absolute',
  top: 'calc(100% - 50px)',
  zIndex: 10,
}))

interface VimeoEmbedProps {
  videoUrl: string
  setBlockDuration: (duration: number) => void
  fallbackUrl: string
  autoPlay?: boolean
}

interface VimeoPlayerProps {
  autoPlay: boolean
}

const VimeoPlayer = styled(Box, {
  shouldForwardProp: (prop: string) => !['autoPlay'].includes(prop),
})<VimeoPlayerProps>(({ autoPlay }) => ({
  backgroundColor: 'black',
  height: '100%',
  left: 'calc(900%/-2)',
  paddingBottom: 'calc(100%/calc(16/9))',
  width: '1000%',

  iframe: {
    border: 'none',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },

  ...(autoPlay && {
    backgroundColor: 'transparent',
    position: 'relative',
  }),
}))

const VimeoEmbed = ({
  videoUrl,
  setBlockDuration,
  fallbackUrl,
  autoPlay = false,
}: VimeoEmbedProps) => {
  const reducedMotionQuery = '(prefers-reduced-motion: reduce)'
  const prefersReducedMotion = window.matchMedia(reducedMotionQuery).matches

  const [player, setPlayer] = useState<Player | null>(null)
  const [showPlayButton, setShowPlayButton] = useState(false)
  const [showFallback, setShowFallback] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [videoTitle, setVideoTitle] = useState('')

  const videoId: number = getVideoId(videoUrl)
  const randomElementId = getRandomVideoElementId()

  const playVideoAction = useCallback(() => void playVideo(player), [player])
  const pauseVideoAction = useCallback(() => void pauseVideo(player), [player])

  useEffect(() => {
    const getVimeoVideo = async () => {
      const player = await loadVideo(
        videoId,
        randomElementId,
        autoPlay,
        prefersReducedMotion
      )
      if (player) {
        const videoTitle = await getVideoTitle(player)
        setVideoTitle(videoTitle)
        setPlayer(player)
      } else {
        setShowFallback(true)
      }
    }
    !player && void getVimeoVideo()
  }, [player, randomElementId, videoId, autoPlay, prefersReducedMotion])

  useEffect(() => {
    if (player) {
      onPlayCallback(player, () => setShowPlayButton(false))
      onPlayingCallback(player, () => setIsPlaying(true))
      onPauseCallback(player, () => setIsPlaying(false))
      onLoadedCallback(player, () => setShowPlayButton(true))
      if (setBlockDuration && typeof setBlockDuration === 'function') {
        getDuration(player)
          .then((duration) => setBlockDuration(duration))
          .catch((error) => console.error(error))
      }
    }
  }, [player, setBlockDuration])

  if (showFallback) {
    return (
      <Box
        data-testid="fallback-player"
        height="100%"
        width="100%"
        position="relative"
        paddingBottom="calc(100%/calc(16/9))"
      >
        <FallbackImage imageUrl={fallbackUrl} />
      </Box>
    )
  }

  return (
    <>
      <Box position="absolute" top="calc(50% - 20px)" left="calc(50% - 20px)">
        <CircularProgress />
      </Box>
      <VimeoPlayer
        id={randomElementId}
        autoPlay={autoPlay}
        data-testid="vimeo-player"
      >
        {showPlayButton && !autoPlay && (
          <VideoPlayButton onClickAction={playVideoAction} />
        )}
      </VimeoPlayer>
      {player && autoPlay && (
        <StyledIconButton
          data-testid="autoplay-play-pause-button"
          onClick={isPlaying ? pauseVideoAction : playVideoAction}
          aria-label={
            isPlaying
              ? `Pausa video: ${videoTitle}`
              : `Spela video: ${videoTitle}`
          }
        >
          <FontIcon
            iconName={isPlaying ? 'PauseCircle' : 'PlayCircle'}
            customColor="#f4f4f4"
          />
        </StyledIconButton>
      )}
    </>
  )
}

export default VimeoEmbed
