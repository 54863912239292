import { isLinkExternal, FontIcon } from '../../'
import { Button } from '@mui/material'

import React from 'react'

export interface AppshellButtonProps {
  url: string
  text: string
  iconName?: string
  variant?: 'outlined' | 'contained'
  dataTestId?: string
  onClick?: () => void
}

const AppshellButton = ({
  url,
  text,
  iconName,
  variant = 'contained',
  dataTestId = 'button',
  onClick,
}: AppshellButtonProps) => {
  const externalButtonLink = isLinkExternal(url)
  const buttonAriaLabel = externalButtonLink
    ? `${text} (öppnas i ny flik)`
    : text

  const getStartIcon = () => {
    if (externalButtonLink) {
      return <FontIcon iconName="launch" />
    } else if (iconName) {
      return <FontIcon iconName={iconName} />
    } else return false
  }

  return (
    <Button
      target={externalButtonLink ? '_blank' : undefined}
      rel={externalButtonLink ? 'noreferrer' : undefined}
      component={externalButtonLink ? 'a' : undefined}
      sx={{ width: 'fit-content' }}
      href={url}
      variant={variant}
      aria-label={buttonAriaLabel}
      startIcon={getStartIcon()}
      data-testid={dataTestId}
      onClick={onClick}
    >
      {text}
    </Button>
  )
}

export default AppshellButton
