import ArrowForward from '@mui/icons-material/ArrowForward'
import { Box, Button, Grid, Typography } from '@mui/material'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import configService from 'utils/configService'
import {
  createImageAltTextFromFileName,
  getFullImageUrl,
  getStandardSrcset,
} from '@trr/internal-helpers'
import { useCachedSettingsState } from 'state/PublicWebApi'
import { handledHttpErrors } from 'state/PublicWebApi/Settings'
import { HTMLMapper } from '@trr/html-mapper'

export const ContentErrorPage = ({
  statusCode,
}: {
  statusCode: handledHttpErrors
}) => {
  const { MEDIA_URL } = configService.config

  const httpErrorContent =
    useCachedSettingsState().errorPageSettings[statusCode]

  return (
    <>
      <Helmet>
        <meta
          name="prerender-status-code"
          content={`${httpErrorContent.errorCode}`}
        />
      </Helmet>
      <Box
        data-testid={`errorPage${httpErrorContent.errorCode}`}
        sx={{
          flexGrow: 1,
          mt: { xs: 5, md: 10 },
          mb: 5,
          maxWidth: '1140px',
          mx: 'auto',
        }}
      >
        <Grid container columns={20}>
          <Grid item xs={20} md={12}>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              Felkod {httpErrorContent.errorCode}
            </Typography>
            <Typography
              sx={{ typography: { xs: 'h3', md: 'h1' } }}
              component={'h1'}
              mb={4}
            >
              {httpErrorContent.heading}
            </Typography>
            <Box mb={4}>
              <HTMLMapper
                mediaUrl={configService.config.MEDIA_URL}
                body={httpErrorContent.ingress}
              />
            </Box>

            <Button
              data-gtm-label="content-error-link"
              href={httpErrorContent.buttonUrl}
              endIcon={<ArrowForward />}
            >
              {httpErrorContent.buttonText}
            </Button>
          </Grid>
          {httpErrorContent.image && (
            <Grid item md={8} sx={{ display: { xs: 'none', md: 'block' } }}>
              <img
                src={getFullImageUrl(MEDIA_URL, httpErrorContent.image, 680)}
                srcSet={getStandardSrcset(MEDIA_URL, httpErrorContent.image, [
                  768,
                ])}
                alt={createImageAltTextFromFileName(httpErrorContent.image)}
                loading="lazy"
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

export default ContentErrorPage
