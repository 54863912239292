import React from 'react'
import { useLocation } from 'react-router-dom'
import { Box, styled, useMediaQuery, useTheme } from '@mui/material'

import { visitorContext } from 'slices/VisitorContext/types'
import { ContextButtonProps } from 'PublicWebApi/Settings/types'
import { useAuthentication } from 'features/Authentication/Hooks'
import { useCachedSettingsState } from 'state/PublicWebApi'
import Logo from 'apps/Site/components/Logo'
import NavbarTop from './Desktop/NavbarTop'
import NavbarBottom from './Desktop/NavbarBottom'
import SecondaryActionButtons from './Desktop/SecondaryActionButtons'
import Navbar from './Mobile/Navbar'
import { useSliceStateSelector } from 'slices/hooks'
import { getLoggedInContext, getLoggedInProfileUrl } from './NewHeader.hooks'
import { LogoLinkWrapper } from './NewHeader.styles'

const HeaderWrapper = styled('header')(() => ({
  width: '100%',
}))

const SimplifiedHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingX: 2,
  paddingY: 0.5,
}))

const NewHeader = () => {
  const { visitorContextStatus } = useSliceStateSelector(
    (state) => state.slices.visitorContexts
  )
  const { megaNavigation } = useCachedSettingsState()
  const { isLoggedIn, profile } = useAuthentication()
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const contextButtonData: ContextButtonProps[] = megaNavigation?.contexts?.map(
    (context) => ({
      title: context.title,
      url: context.url,
      context: context.role as visitorContext,
    })
  )

  const showSimplifiedHeader = () => {
    return (
      location.pathname.includes('/mitt-trr') ||
      location.pathname.includes('/trr-foretag') ||
      location.pathname.includes('/medarbetare')
    )
  }

  const getLogoLink = (): string => {
    return getLoggedInProfileUrl(
      getLoggedInContext(profile?.idp, visitorContextStatus)
    )
  }

  const getLogoAriaLabel = () => {
    if (getLogoLink() === '/trr-foretag') return 'TRR Företag'
    else if (getLogoLink() === '/medarbetare') return 'Medarbetare'
    else return 'Mitt TRR'
  }

  return isMobile ? (
    <HeaderWrapper>
      <Navbar />
    </HeaderWrapper>
  ) : (
    <Box borderBottom={'1px solid'} borderColor={'neutral.divider'}>
      <HeaderWrapper data-testid="new-header">
        {isLoggedIn && showSimplifiedHeader() ? (
          <SimplifiedHeaderWrapper data-testid="simplified-header">
            <LogoLinkWrapper
              sx={{ paddingLeft: 1 }}
              data-testid="simplified-header-logo"
              href={getLogoLink()}
              aria-label={getLogoAriaLabel()}
            >
              <Logo height={48} width={48} />
            </LogoLinkWrapper>
            <SecondaryActionButtons />
          </SimplifiedHeaderWrapper>
        ) : (
          <>
            <NavbarTop contextButtons={contextButtonData} />
            <NavbarBottom navbarBottomMenuItems={megaNavigation?.contexts} />
          </>
        )}
      </HeaderWrapper>
    </Box>
  )
}

export default NewHeader
