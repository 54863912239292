import { Box, Typography } from '@mui/material'
import ButtonAndLink from 'apps/Site/components/ButtonAndLink/ButtonAndLink'
import HTMLMapper from 'apps/Site/Epi/HTMLMapper'
import {
  TextWrapper,
  HeadingWrapper,
  BodyWrapper,
  ImageWrapper,
  PhotoTextBlockWrapper,
} from './PhotoTextBlock.styles'
import React from 'react'
import configService from 'utils/configService'
import {
  createImageAltTextFromFileName,
  getFullImageUrl,
  LazyLoadWrapper,
  getStandardSrcset,
} from '@trr/internal-helpers'

interface PhotoSectionProps {
  image?: string
  useLargeImage?: boolean
}
interface BaseContentProps {
  heading: string
  body?: string
  link?: {
    text: string
    url: string
  }
  button?: {
    text: string
    url: string
  }
  useColor?: boolean
  imagePosition?: 'left' | 'right'
  image?: string
  useLeftAlignedHeadingIfImageNotProvided?: boolean
}

export interface PhotoTextBlockProps {
  content: BaseContentProps & {
    color?: string
    useLargeImage?: boolean
  }
}

const TextSection = ({
  heading,
  body,
  link,
  button,
  useColor,
  imagePosition,
  image,
  useLeftAlignedHeadingIfImageNotProvided,
}: BaseContentProps) => {
  return (
    <TextWrapper
      data-testid="text-section"
      useColor={useColor}
      imagePosition={imagePosition}
      hasImage={!!image}
      useLeftAlignedHeadingIfImageNotProvided={
        useLeftAlignedHeadingIfImageNotProvided
      }
    >
      {heading && (
        <HeadingWrapper
          hasImage={!!image}
          useLeftAlignedHeadingIfImageNotProvided={
            useLeftAlignedHeadingIfImageNotProvided
          }
        >
          <Typography variant="h2">{heading}</Typography>
        </HeadingWrapper>
      )}
      <BodyWrapper
        hasImage={!!image}
        useLeftAlignedHeadingIfImageNotProvided={
          useLeftAlignedHeadingIfImageNotProvided
        }
      >
        {body && <HTMLMapper body={body} />}
        {((button?.text && button?.url) || (link?.text && link?.url)) && (
          <Box mt={5}>
            <ButtonAndLink button={button} link={link} isSecondaryButton />
          </Box>
        )}
      </BodyWrapper>
    </TextWrapper>
  )
}

export const PhotoSection = ({ image, useLargeImage }: PhotoSectionProps) => {
  const { MEDIA_URL } = configService.config

  return (
    <>
      {image && (
        <ImageWrapper useLargeImage={useLargeImage}>
          <LazyLoadWrapper className="LazyLoadWrapper">
            <img
              src={getFullImageUrl(MEDIA_URL, image, 768)}
              srcSet={getStandardSrcset(MEDIA_URL, image, [600])}
              sizes="(max-width: 600px) 100vw, 600px"
              alt={createImageAltTextFromFileName(image)}
              data-testid="photo-section"
            />
          </LazyLoadWrapper>
        </ImageWrapper>
      )}
    </>
  )
}

const PhotoTextBlock = ({
  content: {
    heading,
    body,
    link,
    button,
    image,
    useLargeImage = false,
    imagePosition = 'right',
    color = 'yellow',
    useColor = true,
    useLeftAlignedHeadingIfImageNotProvided = false,
  },
}: PhotoTextBlockProps) => {
  const components = {
    photo: <PhotoSection image={image} useLargeImage={useLargeImage} />,
    text: (
      <TextSection
        heading={heading}
        body={body}
        link={link}
        button={button}
        useColor={useColor}
        imagePosition={imagePosition}
        image={image}
        useLeftAlignedHeadingIfImageNotProvided={
          useLeftAlignedHeadingIfImageNotProvided
        }
      />
    ),
  }

  const photoTextComponentsInOrder =
    imagePosition === 'left' ? (
      <>
        {components.photo}
        {components.text}
      </>
    ) : (
      <>
        {components.text}
        {components.photo}
      </>
    )

  return (
    <Box component="section">
      <PhotoTextBlockWrapper
        bgColor={color}
        useColor={useColor}
        hasImage={!!image}
        useLeftAlignedHeadingIfImageNotProvided={
          useLeftAlignedHeadingIfImageNotProvided
        }
      >
        {photoTextComponentsInOrder}
      </PhotoTextBlockWrapper>
    </Box>
  )
}

export default PhotoTextBlock
