import * as React from 'react'
import { getPageName } from 'utils/helpers'
import configService from 'utils/configService'
import { Helmet } from 'react-helmet-async'
import { BlockPageProps } from 'apps/Site/Epi/PageTypes/Block/Block'
import { getFullImageUrl } from '@trr/internal-helpers'
import { useCachedSettingsState } from 'state/PublicWebApi'
import {
  ArticlePageProperties,
  BasePageProperties,
  FunctionPageProperties,
} from 'state/PublicWebApi/Content'
import { DeepPartial } from 'store'

export type IMetaTagProperties =
  | ArticlePageProperties
  | BlockPageProps
  | FunctionPageProperties
  | BasePageProperties

const metaImageUrl = (url: string) => {
  const { MEDIA_URL } = configService.config
  return getFullImageUrl(MEDIA_URL, url, 768)
}

const MetaTags = ({
  properties,
}: {
  properties: DeepPartial<IMetaTagProperties>
}) => {
  let description: string
  let title: string
  let image: string
  let canonical: string

  const { defaultMetaData } = useCachedSettingsState()

  const defaultTitle = defaultMetaData?.title
  const defaultDescription = defaultMetaData?.description
  const defaultImage = defaultMetaData?.imageUrl

  if (properties) {
    const pageName: string = 'name' in properties && properties.name

    const metaDataTitle = 'metaData' in properties && properties.metaData.title

    title = metaDataTitle || pageName || defaultTitle

    const metaDataDescription: string =
      'metaData' in properties && properties.metaData.description

    const mainIntro: string =
      'puffView' in properties && properties.puffView.mainIntro

    description = metaDataDescription || mainIntro || defaultDescription

    const articleImage: string = 'image' in properties && properties.image

    const heroArea =
      'heroAreaPanel' in properties && properties.heroAreaPanel[0]?.properties

    const heroAreaImage: string =
      typeof heroArea === 'object' && 'image' in heroArea
        ? heroArea?.image
        : undefined

    const puffViewImage: string =
      'puffView' in properties && properties.puffView.image

    image = metaImageUrl(
      articleImage || heroAreaImage || puffViewImage || defaultImage
    )

    const { origin } = window.location
    const path = 'url' in properties ? properties.url : ''
    canonical = `${origin}${path}`
  } else {
    title = defaultTitle
    description = defaultDescription
    image = metaImageUrl(defaultImage)
  }

  const pageTitle = getPageName(title)

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonical} />
      {/* https://www.metatags.nl/alle-meta-tags-overzicht/meta-data-dublin-core/ */}
      <meta name="DC.title" content={title} />
      <meta name="DC.description" content={description} />
      <meta name="DC.image" content={image} />
      <meta name="DC.date" content={new Date().toString()} />
      {/* https://ahrefs.com/blog/open-graph-meta-tags/ */}
      <meta name="og:title" property="og:title" content={title} />
      <meta
        name="og:description"
        property="og:description"
        content={description}
      />
      <meta name="og:image" property="og:image" content={image} />
      <meta name="og:url" property="og:url" content={window.location.href} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:url" content={window.location.href} />
    </Helmet>
  )
}

export default MetaTags
