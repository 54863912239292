import {
  ApplicationInsights,
  SeverityLevel,
} from '@microsoft/applicationinsights-web'
import { MfError } from 'features/ErrorHandling/MfError'
import React, { ErrorInfo, PropsWithChildren, ReactNode } from 'react'
import { WidgetError } from '../WidgetError'

interface ExternalAssetsBoundaryState {
  hasError: boolean
  componentStack: string
}

interface IAppInsightsErrorBoundaryProps {
  appInsights?: ApplicationInsights
  assetType: 'widget' | 'micro-frontend'
}

class ExternalAssetsBoundary extends React.Component<
  PropsWithChildren<IAppInsightsErrorBoundaryProps>,
  ExternalAssetsBoundaryState
> {
  public state = {
    hasError: false,
    componentStack: '',
  }

  public componentDidCatch(error: Error, info: ErrorInfo): void {
    console.error(error, info)
    // eslint-disable-next-line react/no-is-mounted
    this.setState({ hasError: true, componentStack: info.componentStack })

    if (this.props.appInsights) {
      this.props.appInsights.trackException({
        error: error,
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: info,
      })
    }
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      if (this.props.assetType === 'widget') {
        const failingWidgetName = /\/applications\/(.*?)\//.exec(
          this.state.componentStack
        )?.[1]
        return <WidgetError widgetName={failingWidgetName} />
      }
      if (this.props.assetType === 'micro-frontend') {
        return <MfError />
      }
    }
    return this.props.children
  }
}

export default ExternalAssetsBoundary
