import ButtonAndLink from 'apps/Site/components/ButtonAndLink/ButtonAndLink'
import VideoPlayer from 'apps/Site/components/VideoPlayer'
import { isNil } from 'ramda'
import React from 'react'
import configService from 'utils/configService'
import {
  createImageAltTextFromFileName,
  getFullImageUrl,
  getStandardSrcset,
  LazyLoadWrapper,
} from '@trr/internal-helpers'
import {
  HeroContentWrapper,
  HeadingAndIntroWrapper,
  IntroWrapper,
  PreambleWrapper,
  ImageWrapper,
  VideoWrapper,
  HeadingWrapper,
  TextAreaWrapper,
  HeroSection,
} from './HeroBlock.styles'
import { Typography } from '@mui/material'

export interface HeroBlockProps {
  heading: string
  overLineHeading?: string
  preamble: string
  buttonContentArea?: [
    {
      properties: { url: string; text: string }
    },
  ]
  linkContentArea?: [
    {
      properties: { url: string; text: string }
    },
  ]
  image?: string
  hasBigLayout?: boolean
  videoUrl?: string
  fallbackUrl?: string
  useColor?: boolean
  color?: string
  useFoldOutImage?: boolean
}

export interface HeroBlockContent {
  content: HeroBlockProps
}

const HeroBlock = ({
  content: {
    heading,
    overLineHeading,
    preamble,
    buttonContentArea,
    linkContentArea,
    image,
    hasBigLayout,
    videoUrl,
    fallbackUrl,
    color,
    useColor,
    useFoldOutImage,
  },
}: HeroBlockContent) => {
  const { MEDIA_URL } = configService.config
  const buttonContent = buttonContentArea?.[0]?.properties
  const linkContent = linkContentArea?.[0]?.properties

  const useColorStyling = color && useColor

  return (
    <HeroSection
      useColor={useColor}
      color={color}
      hasBigLayout={hasBigLayout}
      useFoldoutImage={useFoldOutImage}
      id={useColorStyling ? 'heroblock-with-color' : 'heroblock-without-color'}
    >
      <HeroContentWrapper
        hasBigLayout={hasBigLayout}
        useFoldoutImage={useFoldOutImage}
      >
        <TextAreaWrapper>
          <HeadingAndIntroWrapper
            hasBigLayout={hasBigLayout}
            useFoldoutImage={useFoldOutImage}
          >
            {heading && (
              <HeadingWrapper>
                <Typography
                  variant="display"
                  component="h1"
                  data-testid="hero-heading"
                >
                  {heading}
                </Typography>
                {overLineHeading && (
                  <Typography variant="h6" component="h2">
                    {overLineHeading}
                  </Typography>
                )}
              </HeadingWrapper>
            )}
            <IntroWrapper>
              {preamble && <PreambleWrapper>{preamble}</PreambleWrapper>}
              {(buttonContent || linkContent) && (
                <ButtonAndLink button={buttonContent} link={linkContent} />
              )}
            </IntroWrapper>
          </HeadingAndIntroWrapper>
        </TextAreaWrapper>
        {videoUrl ? (
          <VideoWrapper
            data-testid={hasBigLayout ? 'video-large' : 'video-small'}
            hasBigLayout={hasBigLayout}
          >
            <LazyLoadWrapper className="LazyLoadWrapper">
              <VideoPlayer url={videoUrl} fallbackUrl={fallbackUrl} autoPlay />
            </LazyLoadWrapper>
          </VideoWrapper>
        ) : (
          !isNil(image) && (
            <ImageWrapper
              hasBigLayout={hasBigLayout}
              useFoldoutImage={useFoldOutImage}
              data-testid={useFoldOutImage && hasBigLayout && 'foldout-image'}
            >
              <LazyLoadWrapper className="LazyLoadWrapper">
                <img
                  src={getFullImageUrl(MEDIA_URL, image, 720)}
                  srcSet={getStandardSrcset(MEDIA_URL, image, [600, 720])}
                  sizes="(max-width: 1024px) 100vw, 720px"
                  alt={createImageAltTextFromFileName(image)}
                  data-testid="image"
                  id={hasBigLayout ? 'image-large' : 'image-small'}
                  // @ts-expect-error Not accepted by tslint for now
                  // eslint-disable-next-line react/no-unknown-property
                  fetchpriority="high"
                />
              </LazyLoadWrapper>
            </ImageWrapper>
          )
        )}
      </HeroContentWrapper>
    </HeroSection>
  )
}

export default HeroBlock
