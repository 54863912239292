import { FontIcon, LazyLoadWrapper } from '@trr/internal-helpers'
import { Box, Typography } from '@mui/material'
import VideoPlayer from 'apps/Site/components/VideoPlayer'
import React, { useState } from 'react'

import {
  ArticleVideoBlockWrapper,
  TitleRowWrapper,
  VideoInformationWrapper,
} from './ArticleVideoBlock.styles'

export interface ArticleVideoBlockProps {
  properties: {
    videoUrl: string
    heading: string
    preamble?: string
    fallbackUrl?: string
  }
}

export const getFormattedTimeStamp = (durationInSeconds: number) => {
  if (durationInSeconds < 3600) {
    return new Date(durationInSeconds * 1000).toISOString().substring(14, 19)
  }
  return new Date(durationInSeconds * 1000).toISOString().substring(11, 19)
}

const ArticleVideoBlock = ({
  properties: { videoUrl, heading, preamble, fallbackUrl },
}: ArticleVideoBlockProps) => {
  const [duration, setDuration] = useState(0)

  return (
    <ArticleVideoBlockWrapper>
      <LazyLoadWrapper className="LazyLoadWrapper">
        <VideoPlayer
          url={videoUrl}
          setBlockDuration={setDuration}
          fallbackUrl={fallbackUrl}
        />
      </LazyLoadWrapper>
      <VideoInformationWrapper data-testid="article-video-block">
        <TitleRowWrapper>
          <Typography variant="h6" component="h2" maxWidth="752px">
            {heading}
          </Typography>
          {duration > 0 && (
            <Box
              display={'flex'}
              data-testid="video-duration"
              aria-label={`Videolängd`}
            >
              <Box component="span" aria-hidden="true" mr={1}>
                <FontIcon iconName="WatchLater" customColor="neutral.light" />
              </Box>
              <Typography variant="subtitle2" component="span" pt="2px">
                {getFormattedTimeStamp(duration)}
              </Typography>
            </Box>
          )}
        </TitleRowWrapper>
        <Typography variant="body1" mt={1} maxWidth="752px">
          {preamble}
        </Typography>
      </VideoInformationWrapper>
    </ArticleVideoBlockWrapper>
  )
}

export default ArticleVideoBlock
