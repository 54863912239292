import React from 'react'
import { Avatar, Typography } from '@mui/material'
import { useCachedUserProfileState } from 'state/PublicWebApi/UserProfile'
import { StyledSecondaryActionButton as StyledAvatarButton } from '../NewHeader.styles'
import MedarbetareAvatarButton from '../../Medarbetare/MedarbetareAvatarButton'
import { getLoggedInProfileUrl } from '../NewHeader.hooks'

interface AvatarButtonProps {
  visitorContext: string
  onClickAction?: () => void
  isMobile?: boolean
  inMobileMenu?: boolean
  onClickMobileAction?: () => void
}

const AvatarButton = ({
  onClickAction,
  visitorContext,
  isMobile,
  inMobileMenu,
  onClickMobileAction,
}: AvatarButtonProps) => {
  const { preferredFirstName, profileImages, imageFileName } =
    useCachedUserProfileState()

  const firstLetter = preferredFirstName?.slice(0, 1)
  const isMedarbetare = visitorContext === 'medarbetare'

  const showPreferredFirstName = !isMobile || (isMobile && inMobileMenu)

  return (
    <>
      {isMedarbetare && !inMobileMenu ? (
        <MedarbetareAvatarButton
          isMobile={isMobile}
          firstName={preferredFirstName}
          image={profileImages?.small}
          imageFileName={imageFileName}
        />
      ) : (
        <StyledAvatarButton
          href={
            isMobile && !isMedarbetare
              ? undefined
              : getLoggedInProfileUrl(visitorContext)
          }
          isMobile={isMobile}
          onClick={isMobile ? onClickMobileAction : onClickAction}
          disableRipple
          key={'header-avatar-button'}
          data-testid={'header-avatar-button'}
          data-gtm-label="header-avatar-button"
        >
          <Avatar
            sx={{ height: '24px', width: '24px', bgcolor: 'primary.main' }}
            {...(imageFileName && {
              src: profileImages?.small,
              alt: 'Profilbild',
            })}
            data-testid="loggedin-avatar"
          >
            <Typography variant="subtitle2">{firstLetter}</Typography>
          </Avatar>
          {showPreferredFirstName && <>{preferredFirstName}</>}
        </StyledAvatarButton>
      )}
    </>
  )
}

export default AvatarButton
