import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, styled } from '@mui/material'
import { AppshellButton as CTAButton } from '@trr/internal-helpers'
import {
  MegaNavigationContext,
  MegaNavigationMenuItem,
} from 'state/PublicWebApi/Settings/types'
import Logo from 'apps/Site/components/Logo'
import { useSliceStateSelector } from 'slices/hooks'
import { FoldoutItem, NavLink } from '../HeaderMenuItems'
import { LogoLinkWrapper } from '../../NewHeader.styles'
import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'

const NavbarBottomWrapper = styled('nav')(({ theme }) => ({
  width: '100%',
  height: '78px',
  display: 'flex',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '1248px',
  justifyContent: 'space-between',
  padding: `${theme.spacing(1.75)} ${theme.spacing(8)} ${theme.spacing(1.75)} ${theme.spacing(8)}`,
  [theme.breakpoints.up('xl')]: {
    maxWidth: theme.breakpoints.values.lg,
    margin: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

interface NavbarBottomProps {
  navbarBottomMenuItems?: MegaNavigationContext[]
}

const NavbarBottom = ({ navbarBottomMenuItems }: NavbarBottomProps) => {
  const [activeNavElement, setActiveNavElement] = useState(-1)
  const [activeContext, setActiveContext] = useState<MegaNavigationContext>(
    navbarBottomMenuItems[0]
  )

  const { visitorContextStatus } = useSliceStateSelector(
    (state) => state.slices.visitorContexts
  )

  const trackCustomClick = useTrackCustomClick()

  const foldoutRef = useRef(null)

  useEffect(() => {
    navbarBottomMenuItems.map((items) => {
      if (visitorContextStatus === items.role) {
        setActiveContext(items)
      }
    })
  }, [visitorContextStatus, navbarBottomMenuItems, setActiveContext])

  const onActiveNavElementToggle = useCallback(
    (index: number) => {
      if (index !== activeNavElement) {
        setActiveNavElement(index)
      } else {
        setActiveNavElement(-1)
      }
    },
    [activeNavElement, setActiveNavElement]
  )

  const onClickFoldoutItem = useCallback(
    (label: string, index: number) => {
      if (index !== activeNavElement) {
        trackCustomClick('Header foldout item', {
          label: `${label} - Öppna`,
        })
      } else {
        trackCustomClick('Header foldout item', {
          label: `${label} - Stäng`,
        })
      }
    },
    [activeNavElement, trackCustomClick]
  )

  useEffect(() => {
    setActiveNavElement(-1)
  }, [visitorContextStatus])

  const closeMenuOnClickOutside = (event: Event) => {
    if (
      activeNavElement !== -1 &&
      foldoutRef.current &&
      !foldoutRef.current.contains(event.target as Node)
    ) {
      setActiveNavElement(-1)
    }
  }

  useEffect(() => {
    document.addEventListener('click', closeMenuOnClickOutside, true)
    return () => {
      document.removeEventListener('click', closeMenuOnClickOutside, true)
    }
  })

  const navItemTypeSwitch = (
    navItemProps: MegaNavigationMenuItem,
    index: number
  ) => {
    if (navItemProps.children.length > 0) {
      return (
        <FoldoutItem
          key={`navbar-bottom-foldout-${index.toString()}`}
          {...navItemProps}
          isFoldoutOpen={activeNavElement === index}
          toggleActiveNavElement={() => onActiveNavElementToggle(index)}
          onClick={() => onClickFoldoutItem(navItemProps.title, index)}
        />
      )
    } else if (navItemProps.children.length === 0) {
      return (
        <NavLink
          key={`navbar-bottom-navlink-${index.toString()}`}
          {...navItemProps}
          toggleActiveNavElement={() => onActiveNavElementToggle(index)}
          onClick={() => {
            trackCustomClick('Header navlink', {
              label: `Klick på länk ${navItemProps.title}`,
              href: navItemProps.url,
            })
          }}
        />
      )
    } else {
      return false
    }
  }

  return (
    <Box ref={foldoutRef}>
      <NavbarBottomWrapper data-testid="navbar-bottom">
        <Box display="flex" alignItems="center">
          <LogoLinkWrapper href="/privatperson" aria-label="Startsida TRR.se">
            <Logo />
          </LogoLinkWrapper>
          <Box mx={5} height="100%" display="flex" gap={1}>
            {activeContext?.menuItems?.map((navItem, index) =>
              navItemTypeSwitch(navItem, index)
            )}
          </Box>
        </Box>
        {activeContext?.ctaButton?.buttonTitle &&
          activeContext?.ctaButton?.buttonUrl && (
            <CTAButton
              dataTestId={`ctabutton-${activeContext.ctaButton.buttonTitle}`}
              variant="outlined"
              text={activeContext.ctaButton.buttonTitle}
              url={activeContext.ctaButton.buttonUrl}
              iconName={activeContext.ctaButton.buttonIcon}
              onClick={() => {
                trackCustomClick('Header Call to action button', {
                  label: `Klick på CTA-knapp ${activeContext.ctaButton.buttonTitle}`,
                  href: activeContext.ctaButton.buttonUrl,
                })
              }}
            />
          )}
      </NavbarBottomWrapper>
    </Box>
  )
}

export default NavbarBottom
