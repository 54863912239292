import React from 'react'
import configService from 'utils/configService/configService'
import { getFullImageUrl, getStandardSrcset } from '@trr/internal-helpers'
import { Box, Typography, styled } from '@mui/material'

const FallbackWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'flex-end',
  backgroundColor: theme.palette.text?.secondary,
  color: 'white',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  overflow: 'hidden',
}))

const FallbackImg = styled('img')(() => ({
  opacity: 0.4,
  width: '90%',
}))

const FallbackImage = ({ imageUrl }: { imageUrl: string }) => {
  const { MEDIA_URL } = configService.config

  const fallbackText = 'Tyvärr kan vi inte spela upp videon just nu'

  return (
    <FallbackWrapper>
      <FallbackImg
        src={getFullImageUrl(MEDIA_URL, imageUrl, 768)}
        alt={fallbackText}
        data-testid="fallback-image"
        srcSet={getStandardSrcset(MEDIA_URL, imageUrl, [1240])}
        sizes="(max-width: 1240px) 80vw, 800px"
        // @ts-expect-error Not accepted by tslint for now
        // eslint-disable-next-line react/no-unknown-property
        fetchpriority="high"
      />
      <Typography
        variant="h5"
        component="p"
        p={1}
        textAlign={'center'}
        position={'absolute'}
        bottom={'40%'}
        aria-hidden="true"
      >
        {fallbackText}
      </Typography>
    </FallbackWrapper>
  )
}

export default FallbackImage
