import React, { useCallback, useMemo, useState } from 'react'
import { asyncCopyToClipboard, asyncMobilePageShare } from 'utils/helpers'
import { useSliceStateSelector } from 'slices/hooks'
import { FontIcon } from '@trr/internal-helpers'
import LinkedIn from '@mui/icons-material/LinkedIn'
import { useAuthentication } from 'features/Authentication/Hooks'
import {
  IconButton,
  Tooltip,
  styled,
  Box,
  TooltipProps,
  tooltipClasses,
} from '@mui/material'

export enum ShareButtonType {
  LinkedIn = 'LinkedIn',
  Facebook = 'Facebook',
  CopyUrl = 'CopyUrl',
  MobileShare = 'MobileShare',
}
interface ShareButtonProps {
  isPublicPage: boolean
}

const ShareButtons = ({ isPublicPage }: ShareButtonProps) => {
  const { isMobile, isTablet } = useSliceStateSelector(
    (state) => state.slices.device.deviceSize
  )

  const { isLoggedIn } = useAuthentication()

  // To be used until toast is implemented
  const [showLinkCopied, setShowLinkCopied] = useState(false)

  const shouldUseMobileShareMenu = useMemo(
    () =>
      (isMobile || isTablet) &&
      navigator?.canShare !== undefined &&
      navigator?.share !== undefined,
    [isMobile, isTablet]
  )

  const encodedWindowLocation = useMemo(
    () => encodeURIComponent(window.location.href),
    []
  )

  const copyValueToClipboard = useCallback((text: string) => {
    asyncCopyToClipboard(text)
      .then(() => setShowLinkCopied(true))
      .catch((err) => {
        console.error(err)
      })
  }, [])

  const openShareLink = useCallback((url: string) => {
    window.open(url, 'popup', 'noreferrer,width=600,height=600')
  }, [])

  const openMobileShare = useCallback(async (url: string) => {
    await asyncMobilePageShare(url)
  }, [])

  const ShareButtonsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginRight: theme.spacing(2),
    gap: theme.spacing(1.5),
    svg: {
      height: '24px',
      width: '24px',
    },
  }))

  const ButtonTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.text?.primary,
      fontSize: '1rem',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.text?.primary,
    },
  }))

  const buttonVariants = useMemo(
    () => ({
      [ShareButtonType.LinkedIn]: {
        IconComponent: <LinkedIn />,
        name: ShareButtonType.LinkedIn,
        onClickAction: () => {
          openShareLink(
            `https://www.linkedin.com/shareArticle?url=${encodedWindowLocation}`
          )
        },
        tooltipText: 'Dela på LinkedIn',
        ariaLabel: 'Dela på LinkedIn. Extern webbplats öppnas i nytt fönster.',
      },
      [ShareButtonType.Facebook]: {
        IconComponent: <FontIcon iconName="Facebook" />,
        name: ShareButtonType.Facebook,
        onClickAction: () => {
          openShareLink(
            `https://www.facebook.com/sharer/sharer.php?u=${encodedWindowLocation}`
          )
        },
        tooltipText: 'Dela på Facebook',
        ariaLabel: 'Dela på Facebook. Extern webbplats öppnas i nytt fönster.',
      },
      [ShareButtonType.CopyUrl]: {
        IconComponent: <FontIcon iconName="InsertLink" />,
        name: ShareButtonType.CopyUrl,
        onClickAction: () => copyValueToClipboard(window.location.href),
        tooltipText: showLinkCopied ? 'Länk kopierad' : 'Kopiera länk',
        ariaLabel: 'Kopiera länk ',
      },
      [ShareButtonType.MobileShare]: {
        IconComponent: <FontIcon iconName="Share" />,
        name: ShareButtonType.MobileShare,
        onClickAction: () => openMobileShare(window.location.href),
        tooltipText: 'Dela',
        ariaLabel: 'Dela',
      },
    }),
    [
      encodedWindowLocation,
      showLinkCopied,
      openShareLink,
      copyValueToClipboard,
      openMobileShare,
    ]
  )

  const shareButtons = useMemo(() => {
    const isLoggedInButtons =
      isLoggedIn && !isPublicPage
        ? [buttonVariants[ShareButtonType.CopyUrl]]
        : [
            buttonVariants[ShareButtonType.Facebook],
            buttonVariants[ShareButtonType.LinkedIn],
            buttonVariants[ShareButtonType.CopyUrl],
          ]
    return [
      ...(shouldUseMobileShareMenu
        ? [buttonVariants[ShareButtonType.MobileShare]]
        : isLoggedInButtons),
    ]
  }, [buttonVariants, shouldUseMobileShareMenu, isLoggedIn, isPublicPage])

  return (
    <ShareButtonsWrapper>
      {shareButtons.map(
        ({ IconComponent, name, onClickAction, tooltipText, ariaLabel }) => (
          <ButtonTooltip
            title={tooltipText}
            placement="top"
            arrow
            key={'tooltip' + name}
          >
            <IconButton
              key={name}
              data-testid={name}
              aria-label={ariaLabel}
              size="small"
              onClick={onClickAction}
            >
              {IconComponent}
            </IconButton>
          </ButtonTooltip>
        )
      )}
    </ShareButtonsWrapper>
  )
}

export default ShareButtons
