import React from 'react'
import { Avatar, Box, Typography } from '@mui/material'
import { FontIcon } from '@trr/internal-helpers'

interface FeaturePuffProps {
  heading: string
  body: string
  variant: 'icon' | 'enumerated'
}

export interface EnumeratedFeaturePuffProps extends FeaturePuffProps {
  variant: 'enumerated'
  index: number
}
export interface IconFeaturePuffProps extends FeaturePuffProps {
  variant: 'icon'
  iconName: string
}

const FeaturePuff = ({
  heading,
  body,
  ...props
}: EnumeratedFeaturePuffProps | IconFeaturePuffProps) => {
  const isEnumerated = props.variant === 'enumerated'

  return (
    <Box>
      <Avatar
        sx={{
          marginBottom: 3,
          backgroundColor: 'surface.green',
          height: '48px',
          width: '48px',
          color: 'secondary.main',
        }}
      >
        {isEnumerated ? (
          props.index.toString()
        ) : (
          <FontIcon iconName={props.iconName} color="secondary" />
        )}
      </Avatar>
      <Typography mb={1} variant="h6" component="h3">
        {heading}
      </Typography>
      <Typography variant="body1">{body}</Typography>
    </Box>
  )
}

export default FeaturePuff
