import React from 'react'
import MenuButton from 'apps/Site/components/MenuButton'
import { Box, Typography, styled } from '@mui/material'

import { MenuGroupBlock } from 'state/PublicWebApi/Settings'
import { FontIcon } from '@trr/internal-helpers'

export interface MenuGroupInterface extends MenuGroupBlock {
  onClickAction?: () => void
  isMenuGroupOpen: boolean
  toggleActiveMenuElement: () => void
}

const GroupWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  listStyle: 'none',
  width: '100%',
}))

const HeadingWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
}))

const GroupContent = styled('ul')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  listStyle: 'none',
  margin: 0,
  padding: 0,
}))

const GroupContentButton = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  width: '100%',
}))

const Group = ({
  heading,
  icon,
  links,
  onClickAction,
  isMenuGroupOpen,
  toggleActiveMenuElement,
}: MenuGroupInterface) => {
  return (
    <li>
      <MenuButton
        onClickAction={toggleActiveMenuElement}
        attributes={{
          'aria-haspopup': 'true',
          'aria-expanded': isMenuGroupOpen,
        }}
      >
        <GroupWrapper>
          <HeadingWrapper>
            <FontIcon iconName={icon} size="small" />
            <Typography variant="subtitle2" component="span">
              {heading}
            </Typography>
          </HeadingWrapper>
          {isMenuGroupOpen ? (
            <FontIcon iconName="ExpandLess" />
          ) : (
            <FontIcon iconName="ExpandMore" />
          )}
        </GroupWrapper>
      </MenuButton>
      {isMenuGroupOpen && (
        <GroupContent>
          {links.map((link) => (
            <li key={`menu-group-link-${link.heading}`}>
              <MenuButton url={link.url} onClickAction={onClickAction}>
                <GroupContentButton>
                  <Typography variant="subtitle2" component="span" pl={4.5}>
                    {link.heading}
                  </Typography>
                </GroupContentButton>
              </MenuButton>
            </li>
          ))}
        </GroupContent>
      )}
    </li>
  )
}

export default Group
