import { customEventData } from '@trr/app-shell-communication'
import { store } from 'store'
import { DataLayer } from 'features/Telemetry/GoogleTagManager/Types'
import { getUserGroup } from './getUserGroup'
import { getWashedQueryString } from './getWashedQueryString'
import { getWashedUserRoles } from './getWashedUserRoles'
import { removeTrailingSlash } from './removeTrailingSlash'
import washUserId from './washUserId'
import { getFilteredTrackedRoles } from './getFilteredTrackedRoles'

export const buildCustomEvent = (e: customEventData): DataLayer => {
  const { mfName, ...rest } = e
  const { userRoles, sub } = store.getState().slices.auth.profile
  const { visitorContextStatus } = store.getState().slices.visitorContexts
  const washedRoles = getWashedUserRoles(userRoles)

  const dataLayer: DataLayer = {
    event: 'custom_event',
    event_context: {
      page: {
        domain: location.hostname,
        path:
          removeTrailingSlash(window.location.pathname) +
          getWashedQueryString(window.location.href),
      },
      mfName,
      visitorContextStatus,
      user: {
        group: washedRoles?.length > 0 ? getUserGroup(washedRoles) : undefined,
        role:
          washedRoles?.length > 0
            ? getFilteredTrackedRoles(washedRoles)
            : undefined,
        id: washUserId(sub, userRoles),
      },
    },
    ...rest,
  }

  return dataLayer
}
