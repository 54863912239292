import React from 'react'
import { Box, Typography } from '@mui/material'
import { isNil } from 'ramda'
import { AppshellButton } from '@trr/internal-helpers'

export interface IntroductionContentProps {
  heading: string
  text: string
  callToAction?: {
    url: string
    text: string
  }
}

interface IntroductionProps {
  content: IntroductionContentProps
}

const Introduction = ({
  content: {
    heading,
    text,
    callToAction: { text: btnText, url } = { text: null, url: null },
  },
}: IntroductionProps) => {
  const preamble = text
    ?.split(/(\r?\n){2,}/g)
    .map((t) => t.trim())
    .filter(Boolean)
    .map((paragraph) => (
      <Typography key={paragraph.slice(0, 10)} variant="preamble" gutterBottom>
        {paragraph}
      </Typography>
    ))
  return (
    <Box mb={5}>
      {!isNil(heading) && (
        <Typography variant="h1" mb={3}>
          {heading}
        </Typography>
      )}
      {!isNil(text) && preamble}
      {!isNil(btnText) && !isNil(url) && (
        <AppshellButton
          dataTestId={'callToActionButtonIntroduction'}
          url={url}
          text={btnText}
        />
      )}
    </Box>
  )
}

export default Introduction
