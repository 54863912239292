import { internal_trackGtmCustomEvent } from '@trr/app-shell-communication'
import { internal } from '@trr/gtm-tracking'
import { useCallback } from 'react'
import { useCachedFeatureFlagsState } from 'PublicWebApi'

/**
 * A hook that allows the user to track custom clicks. This can be usedfull when base tracking does not
 * cover the needs.
 *
 * @param  {string}  identifier - The identifier of of the item clicked
 * @param  {string}  options.label - The potential inner text of the item clicked
 * @param  {string}  options.href - The potential href of the item clicked.
 * @returns {function(): void} The trigger function used to send an event to the datalayer in GTM.
 */

export const useTrackCustomClick = () => {
  const useNewNavigationTracking = useCachedFeatureFlagsState().includes(
    'Public-Web-Frontend_newNavigationGTMTracking_temp_240701'
  )

  const trackerFunction = useCallback(
    (identifier: string, options?: { label?: string; href?: string }) => {
      const customClickEvent = internal.buildCustomClickEvent(
        identifier,
        options,
        undefined,
        true
      )
      internal_trackGtmCustomEvent(customClickEvent)
    },
    []
  )

  return useNewNavigationTracking ? trackerFunction : () => {}
}
